/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
import {
  Service,
  SubItems,
  Hero2,
} from 'components/elements'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'

const PageTemplate = ({
  data: {
    page: {
      wordpress_id: id,
      title,
      path,
      acf,
      yoast_meta: yoast,
    },
  },
}) => {
  return (
    <Layout activeHeader={title}>
      <SEO yoast={yoast} path={path} />

      <Hero2 fields={acf.banner} />

      <div className="mb-5 pb-5">
        <div className="mb-5 pb-5">
          <SubItems fields={acf.blok_1} parentId={id} />
        </div>
      </div>

      <div className="pb-lg-5 pt-5 mt-5">
        <div className="pt-5">
          <Service
            fields={acf.blok_2}
            direction="right"
          />
        </div>
      </div>
      
      <div className="py-5" />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      wordpress_id
      title
      path

      acf {
        banner {
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        blok_1 {
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }

        blok_2 {
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
